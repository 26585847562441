import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Button from "../components/button"
import Seo from "../components/seo"

import StoryblokService from "../utilities/storyblok-service"

import "../styles/topic-pages.scss"
import { StaticImage } from "gatsby-plugin-image"

const TopicPage3 = (context) => {
  const pageContent = JSON.parse(context.pageContext.story.content)
  return (
    <Layout>
      <Seo title={context.pageContext.story.name} />
      <Link to="/" className="backButton">
        <div className="icon"></div>
        <div className="label">Zurück zur Übersicht</div>
      </Link>
      <div className="topic-page">
        <h1>{context.pageContext.story.name}</h1>
        <div className="split-page">
          <div className="full-page">
          <h2>Welche Unterstützung bekomme ich und wo kann ich mich informieren?</h2>
          <div className="textbox" dangerouslySetInnerHTML={{ __html: StoryblokService.client.richTextResolver.render( pageContent.ressources )}} />
          <div className="actions">
            <Button type="secondary" to={`/${context.pageContext.story.full_slug}/analyse`} label="Zurück" />
            <Button type="primary" to={`/${pageContent.nextTopic.cached_url}/analyse`} label="Nächstes Thema" />
          </div>
          </div>
          <div className="contact-box">
          <h2>Noch Fragen?</h2>
          <div className="composite-box">
            <StaticImage 
              src="../images/contact-image.jpg"
              width={400}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Noch Fragen?"
              loading="eager"
              placeholder="none" 
            />
            <div className="text">
              <h4>Kontaktieren Sie uns gerne!</h4>
              <span>Bezirkregierung Münster</span>
              <Link to="/kontakt">Nachricht schreiben</Link>
            </div>
          </div>
        </div>
        </div>
      </div>
    </Layout>
  )
}

export default TopicPage3
